





























































import Vue from 'vue'
import Component from 'vue-class-component'
import ListView from '@/components/list/ListView.vue'
import PageTab from '@/components/ui/PageTab.vue'
import { formatDateTimeToLocalDateTime } from '@/utils/dateUtils'

@Component({
  components: {
    ListView,
    PageTab,
  },
})
export default class List extends Vue {
  private listViewKey = 0
  private loading = false

  private showMoreQueryStringsDialog = false
  private queryStringsFullText = ''

  private headers = []

  created() {
    this.setHeaders()
  }

  private get url() {
    return '/v4/sysop/api-request-logs'
  }

  private setHeaders() {
    this.headers = [
      {
        text: 'ID',
        value: 'id',
        filter: { disable: false },
      },
      {
        text: 'c:api-request-log:Time of call',
        value: 'timeOfCall',
        filter: { disable: false },
      },
      {
        text: 'c:api-request-log:Total request time',
        value: 'totalRequestTime',
        filter: { disable: false },
      },
      {
        text: 'c:api-request-log:Site ID',
        value: 'siteId',
        filter: { disable: false },
      },
      {
        text: 'c:api-request-log:Api key',
        value: 'apiKey',
        sortable: false,
        filter: { disable: true },
      },
      {
        text: 'c:api-request-log:Query strings',
        value: 'queryStrings',
        sortable: false,
        filter: { disable: true },
      },
      {
        text: 'c:api-request-log:Method',
        value: 'method',
        sortable: false,
        filter: { disable: true },
      },
      {
        text: 'c:api-request-log:Raw route',
        value: 'rawRoute',
        sortable: false,
        filter: { disable: true },
      },
    ]
  }

  private formatDate(date) {
    return formatDateTimeToLocalDateTime(date)
  }

  private showFullText(text) {
    this.queryStringsFullText = text
    this.showMoreQueryStringsDialog = true
  }
}
